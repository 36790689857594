<template>
  <base-layout>
    <add-edit-tax-plan />
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <div class="">
            <title-plus
              :title="$t('components.taxPlanManagement.headline')"
              @plus="add"
              :hide-plus="$acl.canNotView('Tax Plans')"
            />
          </div>
          <div class="flex md:justify-end">
            <date-range-picker
              @apply-date="handleApplyDate($event)"
              @cancel-date="handleCancelDate($event)"
            />
          </div>
        </div>

        <div
          class="flex flex-col px-4 mt-5 space-y-5 lg:items-center lg:space-y-0 lg:space-x-5 lg:flex-row mb-10"
        >
          <SummaryCard
            :title="$t('components.taxPlanManagement.summary.numberOfTaxes')"
            :value="indexMetaData.summary.total"
            variant="gray"
          />
        </div>
      </div>

      <div
        v-if="$acl.canNotView('Tax Plans')"
        class="py-5 font-bold text-center text-gray-600"
      >
        {{ $t('components.acl.doNotHavePermission') }}
      </div>

      <FSTable
        v-else
        :fst-id="`TaxPlanIndex`"
        :headers="getTableHeaders"
        :endpoint="indexDataEndpoint"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <template v-if="getTableMode === 'full'">
              <FSTableRow :key="itemIndex" text-fallback-always>
                <FSTableRowItem :text="item.id" :truncate="-5" />

                <FSTableRowItem :text="item.name" />

                <FSTableRowItem :text="item.description || '--'" />

                <FSTableRowItem> {{ item.percentage }}% </FSTableRowItem>

                <FSTableRowItem :text="item.created_at" date />

                <FSTableRowItem>
                  <x-status
                    :variant="item.status_active ? 'green' : 'gray'"
                    :text="item.status_active ? 'Active' : 'Inactive'"
                    size="small"
                  />
                </FSTableRowItem>

                <FSTableRowItem>
                  <div class="flex items-center">
                    <oto-edit-icon @click="edit(item)" />
                  </div>
                </FSTableRowItem>
              </FSTableRow>
            </template>
            <template v-if="getTableMode === 'responsive'">
              <FSTableRow :key="`fs-table-row-${itemIndex}`" textFallbackAlways>
                <FSTableRowItem>
                  <div
                    class="col-span-1 focus:text-gray-400"
                    @click="toggle(itemIndex)"
                  >
                    <i
                      class="fas fa-minus-circle"
                      style="color:#d90a20;"
                      v-if="opened.includes(itemIndex)"
                    ></i>
                    <i class="fas fa-plus-circle" v-else></i>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem :text="item.name" />
                <FSTableRowItem> {{ item.percentage }}% </FSTableRowItem>
                <FSTableRowItem>
                  <x-status
                    :variant="item.status_active ? 'green' : 'gray'"
                    :text="item.status_active ? 'Active' : 'Inactive'"
                    size="small"
                  />
                </FSTableRowItem>
              </FSTableRow>
              <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                <td colspan="10">
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{ $t('components.taxPlanManagement.table.columns.id') }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ item.id | onlyLastFive }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.taxPlanManagement.table.columns.description'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ item.description || '--' }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.taxPlanManagement.table.columns.createdAt'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{
                        getFormattedDateTime(
                          item.created_at,
                          'D MMM YYYY, h:mm:ss a'
                        )
                      }}
                    </div>
                  </div>

                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t('components.taxPlanManagement.table.columns.actions')
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      <div class="flex items-center">
                        <oto-edit-icon @click="edit(item)" />
                      </div>
                    </div>
                  </div>
                </td>
              </FSTableRow>
            </template>
          </template>
        </template>
      </FSTable>
    </content-section>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
import XStatus from '@/components/badge/XStatus'

import { EventBus } from '@/utils/EventBus'
import { TaxConfig } from '@/config/TaxConfig'
import { getFormattedDateTime } from '@/utils/datetime'
import SummaryCard from '@/components/cards/SummaryCard'
import AddEditTaxPlan from './TaxPlanAddEdit'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  onApplyFilterDateRange,
} from '@/components/fs-table'

export default {
  name: 'ViewTaxPlan',
  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    OtoEditIcon,
    DateRangePicker,
    SummaryCard,
    XStatus,
    AddEditTaxPlan,
    FSTable,
    FSTableRow,
    FSTableRowItem,
  },
  data() {
    return {
      indexMetaData: {
        summary: {
          total: 0,
        },
      },
      indexDataEndpoint: TaxConfig.api.index,
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        {
          text: `${this.$t('components.taxPlanManagement.table.columns.id')}`,
          width: '10%',
          field: 'name',
          sort: null,
        },
        {
          text: `${this.$t('components.taxPlanManagement.table.columns.name')}`,
          width: '15%',
          field: 'name',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.taxPlanManagement.table.columns.description'
          )}`,
          width: '25%',
          field: 'description',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.taxPlanManagement.table.columns.percentage'
          )}`,
          width: '15%',
          field: 'percentage',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.taxPlanManagement.table.columns.createdAt'
          )}`,
          width: '20%',
          field: 'created_at',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.taxPlanManagement.table.columns.status'
          )}`,
          width: '20%',
          field: 'status_active',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.taxPlanManagement.table.columns.actions'
          )}`,
          width: '15%',
          field: null,
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: `${this.$t('components.taxPlanManagement.table.columns.name')}`,
          width: '15%',
          field: 'name',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.taxPlanManagement.table.columns.percentage'
          )}`,
          width: '15%',
          field: 'percentage',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.taxPlanManagement.table.columns.status'
          )}`,
          width: '20%',
          field: 'status_active',
          sort: null,
        },
      ],
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
  },
  methods: {
    onApplyFilterDateRange,
    getFormattedDateTime,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    add: function() {
      EventBus.$emit(TaxConfig.events.editingData, {})
      dispatchEvent(new Event(TaxConfig.events.sorToggle))
    },
    edit: function(item) {
      EventBus.$emit(TaxConfig.events.editingData, item)
      dispatchEvent(new Event(TaxConfig.events.sorToggle))
    },
  },
  async created() {
    // todo: remove the event listener on destroyed hook
    window.addEventListener(TaxConfig.events.refresh, () => {
      this.getIndexData(this.getQueryString)
    })
  },
}
</script>
<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
